/* import __COLOCATED_TEMPLATE__ from './zendesk-chat-import-component.hbs'; */
/* RESPONSIBLE TEAM: team-ml */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import OAuthProviders from 'embercom/lib/import/oauth-providers';

export default class ZendeskChatImportComponent extends Component {
  redirectUrl = OAuthProviders.zendesk_chat.redirectUrl;

  @service intercomEventService;
  @service store;

  @tracked currentStep = 1;
  @tracked clientId = '';
  @tracked clientSecret = '';
  @tracked subdomain = '';

  get hasNotEnteredCredentials() {
    return !this.clientId || !this.clientSecret || !this.subdomain;
  }

  constructor() {
    super(...arguments);
    this._trackPageView();
  }

  _trackPageView() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: `instructions_page_${this.currentStep}`,
      context: 'zendesk_chat_import',
    });
  }

  @action
  previousStep() {
    this.currentStep--;
    this._trackPageView();
  }

  @action
  nextStep() {
    this.currentStep++;
    this._trackPageView();
  }
}
